@import "react-slideshow-image/dist/styles.css";
@keyframes cf4FadeInOut {
  0% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  92% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

* {
  font-family: 'Inter', sans-serif;
}

#slider img:nth-of-type(1) {
  animation-delay: 6s;
}
#slider img:nth-of-type(2) {
  animation-delay: 4s;
}

#slider img {
  animation-name: cf4FadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

button.nav.default-nav {
  display: none;
}

a.active {
  background-color: #ce1326;
  color: #fff;
}

.selected-address {
  background-color: #ce1326;
  color: #fff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Loader */
.placeholder-content {
  overflow: hidden;
  background: #000;
  position: relative;
  -webkit-animation-duration: 1.7s;
  animation-duration: 1.7s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: placeholderAnimate;
  animation-name: placeholderAnimate;
  background: #aeb1b3;
  background: radial-gradient(
    circle,
    rgba(223, 223, 223, 1) 0%,
    rgba(245, 245, 245, 1) 100%
  );
  background-size: 1300px;
}

.placeholder-content-blue {
  background: radial-gradient(circle, #e2e8f0 0%, #fff 100%) !important;
}
.placeholder-content_item {
  width: 100%;
  position: absolute;
  background: rgb(147, 186, 249);
  z-index: 2;
}

@-webkit-keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}

@keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}
