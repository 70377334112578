@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
body {
  margin: 0; 
  color: #B9861F;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

.font-dancing{
  font-family: 'Dancing Script', cursive;
}